import React, { useState, useEffect } from 'react';
import './StatusProgressBar.css';

const StatusProgressBar = ({ statuses, activeStatus }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const index = statuses.indexOf(activeStatus);
    if (index !== -1) {
      setCurrentIndex(index);
    }
  }, [activeStatus, statuses]);

  const progressWidth = (currentIndex / (statuses.length - 1)) * 100;

  return (
    <div className="status-progress-bar">
      <div className="status-progress-bar-track">
        <div
          className="status-progress-bar-progress"
          style={{ width: `${progressWidth}%` }}
        />
        {statuses.map((status, index) => (
          <div
            key={status}
            className={`status-progress-bar-step ${
              index <= currentIndex ? 'active' : ''
            }`}
            style={{ left: `${(index / (statuses.length - 1)) * 100}%` }}
          >
            <div className="status-progress-bar-step-circle" />
            <span className="status-progress-bar-step-label">{status}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StatusProgressBar;
