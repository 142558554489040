import { BACKEND_URL } from '../../assets/constants';
import useFetch from '../useFetch.ts';

interface Feedback {
  id: number;
  photo: string;
  text: string;
  stars: number;
  nickname: string;
  createdAt: string;
  updatedAt: string;
}

const useFetchFeedbacks = (params: Record<string, string | number> = {}) => {
  const url = `${BACKEND_URL}/api/feedback/latest`;
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    params,
  };

  return useFetch<Feedback[]>(url, options);
};

export default useFetchFeedbacks;
