import React, { useEffect, useState } from "react";
import classNames from "classnames";
import StatusProgressBar from "../StatusProgressBar/StatusProgressBar.js";
import Globus from "../../../assets/img/globe.png";
import Steam from "../../../assets/img/steam.svg";
import { ItemBlock } from "../ItemBlock/ItemBlock.js";
import { formatDate } from "../../../util/formatDate.ts";

const statuses = {
  _: "Транзакция создана",
  create: "Трейд создан",
  __: "Ожидание принятия трейда",
  paymenting: "Выплата...",
  success: "Завершена",
};

const statusesCancel = {
  _: "Транзакция создана",
  create: "Трейд создан",
  cancel: "Трейд отменен",
};

const Transaction = ({ transaction, transactionData }) => {
  const [timeLeft, setTimeLeft] = useState("20:00");

  if(!transaction) transaction = transactionData.data




  useEffect(() => {
    const countdownDuration = 20 * 60 * 1000; // 20 minutes in milliseconds
    const endTime = new Date(
      new Date(transaction.createdAt).getTime() + countdownDuration
    );

    const updateTimer = () => {
      const now = new Date();
      const timeDiff = endTime - now;

      if (timeDiff <= 0) {
        setTimeLeft("00:00");
        return;
      }

      const minutes = String(
        Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60))
      ).padStart(2, "0");
      const seconds = String(
        Math.floor((timeDiff % (1000 * 60)) / 1000)
      ).padStart(2, "0");
      setTimeLeft(`${minutes}:${seconds}`);
    };

    const intervalId = setInterval(updateTimer, 1000);

    // Initial call to set the time immediately
    updateTimer();

    return () => clearInterval(intervalId);
  }, [transaction.createdAt]);

  useEffect(() => {
    let intervalId;

    if (
      transaction.status === "paymenting" ||
      transaction.status === "create"
    ) {
      intervalId = setInterval(() => {
        transactionData.refetch();
      }, 15000); // 15 seconds
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [transaction.status]);

  const timeDisplay =
    transaction.status === "create"
      ? timeLeft
      : transaction.status === "paymenting"
        ? "Ожидайте..."
        : undefined;

  const items = transaction?.items

  return (
    <div className="main-window-auth-trade">
      <h2>Сделка #{transaction.transactionId}</h2>
      <StatusProgressBar
        statuses={
          statuses[transaction.status]
            ? Object.values(statuses)
            : Object.values(statusesCancel)
        }
        activeStatus={
          statuses[transaction.status] || statusesCancel[transaction.status]
        }
      />
      <div className="main-window-auth-trade_container">
        <div className="main-window-auth-trade_info">
          <div className="main-window-auth-trade_info-data">
            <h2>Информация о сделке</h2>
            <div>
              <div>
                <span>SteamID Бота:</span>
                <span>Имя бота:</span>
                <span>Дата регистрация бота:</span>
                <span>Трейд id:</span>
                {timeDisplay && <span>Осталось времени:</span>}
              </div>
              <div>

                <a
                  target="_blank"
                  href={`https://steamcommunity.com/profiles/${transaction.SteamIDBot}`}
                >
                  {transaction.SteamIDBot}
                </a>
                {transaction?.nicknameBot && <span>{transaction?.nicknameBot}</span>}
                {transaction?.dateRegBot && <span>{formatDate(new Date(transaction?.dateRegBot))}</span>}
                <a
                  target="_blank"
                  href={`https://steamcommunity.com/tradeoffer/${transaction.tradeId}`}
                >
                  #{transaction.tradeId}
                </a>
                {timeDisplay && <span>{timeDisplay}</span>}
              </div>
            </div>
          </div>

          {transaction.status === "create" && (
            <div className="main-window-auth-trade_info-button_container">
              <h2>Примите трейд</h2>
              <div className="main-window-auth-trade_info-button">
                <a
                  target="_blank"
                  href={`https://steamcommunity.com/tradeoffer/${transaction.tradeId}`}
                >
                  <img src={Globus} alt="Globus" />
                  Принять трейд в браузере
                </a>

                <a
                  target="_blank"
                  href={`steam://openurl/https://steamcommunity.com/tradeoffer/${transaction.tradeId}`}
                >
                  <img src={Steam} alt="Steam" />
                  Принять трейд в клиенте
                </a>
              </div>
            </div>
          )}
        </div>
        <div className="main-window-auth-trade_items">
          {items && items.map(v => <ItemBlock item={{ ...v, allowed: true }} />)}
        </div>
      </div>

    </div>
  );
};

export default Transaction;
