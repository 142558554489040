import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Sell from "./page/Sell";
import Footer from "./components/component/Footer/Footer";
import Profile from "./page/Profile";


function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Sell />} />
        <Route path="/sell" element={<Sell />} />
        <Route path="/sell/transaction/:transactionId" element={<Sell />} />
        <Route path="/profile" element={<Profile />} />
        {/* <Route path="/parther" element={<Features />} />
        <Route path="/bonus" element={<Testimonials />} />
        <Route path="/feedback" element={<LiveFeed />} /> */}
        {/* <Route path="/faq" element={<Stats />} /> */}
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
