import React, { useState } from "react";

const SvgIcon2 = ({ isHovered }) => {
  const strokeColor = isHovered ? "#6EAEFA" : "#797979";
  const transitionStyle = {
    transition: "stroke 0.2s",
  };

  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.49984 18.3327H12.4998C16.6665 18.3327 18.3332 16.666 18.3332 12.4993V7.49935C18.3332 3.33268 16.6665 1.66602 12.4998 1.66602H7.49984C3.33317 1.66602 1.6665 3.33268 1.6665 7.49935V12.4993C1.6665 16.666 3.33317 18.3327 7.49984 18.3327Z"
        stroke={strokeColor}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={transitionStyle}
      />
      <path
        d="M6.1084 12.0758L8.09173 9.50078C8.37507 9.13411 8.90007 9.06745 9.26673 9.35078L10.7917 10.5508C11.1584 10.8341 11.6834 10.7674 11.9667 10.4091L13.8917 7.92578"
        stroke={strokeColor}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={transitionStyle}
      />
    </svg>
  );
};

export default SvgIcon2;
