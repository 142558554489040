import React from "react";
import useUser from "../hooks/user/useUser.ts";
import BlockInfoUser from "../components/component/BlockInfoUser/BlockInfoUser.js";

function Profile() {
  const user = useUser();

  if (!user) return null;

  return (
    <>
      <BlockInfoUser user={user} />
      {/* <Transaction /> */}
    </>
  );
}

export default Profile;
