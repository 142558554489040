import { useState, useCallback, useEffect } from "react";
import { BACKEND_URL } from "../../assets/constants.js";
import { apiRequest } from "../../util/apiRequest.ts";

interface UserData {
  typeAuth: string;
  tradeurl: string | null;
  email: string | null;
  transactionCount: number;
  totalTransactionSum: number;
}

const useFetchUser = (params: Record<string, string | number> = {}) => {
  const [data, setData] = useState<UserData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const responseData = await apiRequest<UserData>(`/api/user`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        body: params, // Include params as the body if necessary
      });
      setData(responseData);
    } catch (err) {
      setError(err as Error);
    } finally {
      setLoading(false);
    }
  }, [params]);

  // Fetch data on mount
  useEffect(() => {
    fetchData();
  }, []);

  return { data, loading, error, refetch: fetchData };
};

export default useFetchUser;
