import { useState, useEffect } from 'react';
import axios, { AxiosRequestConfig } from 'axios';

interface FetchOptions extends AxiosRequestConfig {
  params?: Record<string, string | number>;
}

const getAccessTokenFromCookies = (): string | null => {
  const match = document.cookie.match(new RegExp('(^| )access_token=([^;]+)'));
  return match ? decodeURIComponent(match[2]) : null;
};

const useFetch = <T>(url: string, options: FetchOptions = {}): { data: T | null; loading: boolean; error: Error | null } => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { params, headers = {}, ...axiosOptions } = options;

        // Get the access token from cookies
        const accessToken = getAccessTokenFromCookies();

        // Add the access token to the Authorization header
        if (accessToken) {
          headers['Authorization'] = `Bearer ${accessToken}`;
        }

        const response = await axios<T>(url, {
          ...axiosOptions,
          headers,
          params,
          // withCredentials: true, // Ensure cookies are sent along with the request
        });

        setData(response.data);
      } catch (error) {
        setError(error as Error);
      }
      setLoading(false);
    };

    fetchData();
  }, [url, JSON.stringify(options)]);

  return { data, loading, error };
};

export default useFetch;
