// DropdownMenu.tsx
import React, { useState, useEffect, useRef } from 'react';
import ArrowDown from "../../../assets/img/arrow_down.png";
import './DropdownMenu.css';

const DropdownMenu = ({ items }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="dropdown-container" ref={dropdownRef}>
      <div onClick={toggleDropdown} className="dropdown-toggle">
        <img src={ArrowDown} className={`arrow ${isDropdownOpen ? 'up' : 'down'}`} />
      </div>
      {isDropdownOpen && (
        <div className={`dropdown-menu ${isDropdownOpen ? 'open' : ''}`}>
          {items.map((item, index) => (
            <div key={index} className="dropdown-item">
              {item}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
