import { BACKEND_URL } from '../../assets/constants.js';
import useFetch from '../useFetch.ts';

interface Info {
  skin_transaction_count: number;
  user_count: number;
  feedback_count: number;
 
}

const useFetchInfo = (params: Record<string, string | number> = {}) => {
  const url = `${BACKEND_URL}/api/feedback/info`;
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    params,
  };

  return useFetch<Info[]>(url, options);
};

export default useFetchInfo;
