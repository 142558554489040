import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/img/Logo.svg";
import MoneySend from "../assets/img/money-send.js";
import Activity from "../assets/img/activity.js";
import EmptyWalletTime from "../assets/img/empty-wallet-time.js";
import InfoCircle from "../assets/img/info-circle.js";
import ReceiptDiscount from "../assets/img/receipt-discount.js";
import Steam from "../assets/img/steam.svg";
import { BACKEND_URL } from "../assets/constants.js";
import "./Header.css"; // Подключение стилей
import useUser from "../hooks/user/useUser.ts";
import { LoginBlock } from "./LoginBlock";

const Header = () => {
  const [hoveredItem, setHoveredItem] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);

  const user = useUser();

  const handleMouseEnter = (item) => {
    setHoveredItem(item);
  };

  const handleMouseLeave = () => {
    setHoveredItem("");
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header>
      <div className="logo">
        <img src={Logo} alt="SkinnSeller" />
      </div>

      <nav className={menuOpen ? "open" : ""}>
        {/* <div
          className={classNames({ ["buy"]: true, ["buy-open"]: true })}
          onMouseEnter={() => handleMouseEnter("buy")}
          onMouseLeave={handleMouseLeave}
        >
          <MoneyRecive isHovered={hoveredItem === "buy"} />
          <Link>Купить скины (Soon)</Link>
        </div> */}
        <div
          className="sell"
          onMouseEnter={() => handleMouseEnter("sell")}
          onMouseLeave={handleMouseLeave}
        >
          <MoneySend isHovered={hoveredItem === "sell"} />
          <Link to="/sell">Продать скины</Link>
        </div>
        <div
          className="faq-partner"
          onMouseEnter={() => handleMouseEnter("emptyWallet")}
          onMouseLeave={handleMouseLeave}
        >
          <EmptyWalletTime isHovered={hoveredItem === "emptyWallet"} />
          <Link to="/parther">Партнерка</Link>
        </div>
        <div
          className="feedback-bonus"
          onMouseEnter={() => handleMouseEnter("receiptDiscount")}
          onMouseLeave={handleMouseLeave}
        >
          <ReceiptDiscount isHovered={hoveredItem === "receiptDiscount"} />
          <Link to="/bonus">Бонусы</Link>
        </div>
        <div
          className="feedback-bonus"
          onMouseEnter={() => handleMouseEnter("activity")}
          onMouseLeave={handleMouseLeave}
        >
          <Activity isHovered={hoveredItem === "activity"} />
          <Link to="/feedback">Отзывы</Link>
        </div>
        <div
          className="faq-partner"
          onMouseEnter={() => handleMouseEnter("infoCircle")}
          onMouseLeave={handleMouseLeave}
        >
          <InfoCircle isHovered={hoveredItem === "infoCircle"} />
          <Link to="/faq">FAQ</Link>
        </div>
      </nav>
      {!user ? (
        <div className="login">
          <img  onClick={() =>  window.open(`${BACKEND_URL}/api/auth/steam`)} src={Steam} />
          <a href={`${BACKEND_URL}/api/auth/steam`}>Login with Steam</a>
        </div>
      ) : (
        <LoginBlock />
      )}

      <div
        className={`hamburger-menu ${menuOpen ? "open" : ""}`}
        onClick={toggleMenu}
      >
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
    </header>
  );
};

export default Header;
