import "./AboutMain.css";

const AboutMain = () => {
  return (
    <div className="about_container">
      <div>
        <h3>Продай скины за реальные деньги</h3>
        <p>
          Мы, Avan.Market и мы поможем вам продать скины за реальные деньги
          быстро и безопасно. Мы работаем с 2018 года и за это время смогли
          обрести хорошую репутацию на рынке покупки скинов. Тысячи людей
          доверяют нам ежедневно и продают свои скины CS2, DOTA2, RUST и Team
          Fortress 2 по высоким ценам.  Продавать свои скины на торговой
          площадке Steam не всегда выгодно, причин на это много, а самая главная
          - там нельзя вывести деньги. Мы же позволяем мгновенно получить деньги
          за скины. Поэтому если у вас имеются старые и ненужные скины, то вы их
          смело можете обменять на реальные деньги на нашем проекте. Из-за того,
          что мы скупаем скины по ценам выше, чем у других, это позволяет нам
          быть №1 на рынке скинов.
        </p>
      </div>

      <div>
        <h3>Продай скины за реальные деньги</h3>
        <p>
          Мы, Avan.Market и мы поможем вам продать скины за реальные деньги
          быстро и безопасно. Мы работаем с 2018 года и за это время смогли
          обрести хорошую репутацию на рынке покупки скинов. Тысячи людей
          доверяют нам ежедневно и продают свои скины CS2, DOTA2, RUST и Team
          Fortress 2 по высоким ценам.  Продавать свои скины на торговой
          площадке Steam не всегда выгодно, причин на это много, а самая главная
          - там нельзя вывести деньги. Мы же позволяем мгновенно получить деньги
          за скины. Поэтому если у вас имеются старые и ненужные скины, то вы их
          смело можете обменять на реальные деньги на нашем проекте. Из-за того,
          что мы скупаем скины по ценам выше, чем у других, это позволяет нам
          быть №1 на рынке скинов.
        </p>
      </div>

      <div>
        <h3>Продай скины за реальные деньги</h3>
        <p>
          Мы, Avan.Market и мы поможем вам продать скины за реальные деньги
          быстро и безопасно. Мы работаем с 2018 года и за это время смогли
          обрести хорошую репутацию на рынке покупки скинов. Тысячи людей
          доверяют нам ежедневно и продают свои скины CS2, DOTA2, RUST и Team
          Fortress 2 по высоким ценам.  Продавать свои скины на торговой
          площадке Steam не всегда выгодно, причин на это много, а самая главная
          - там нельзя вывести деньги. Мы же позволяем мгновенно получить деньги
          за скины. Поэтому если у вас имеются старые и ненужные скины, то вы их
          смело можете обменять на реальные деньги на нашем проекте. Из-за того,
          что мы скупаем скины по ценам выше, чем у других, это позволяет нам
          быть №1 на рынке скинов.
        </p>
      </div>
    </div>
  );
};

export default AboutMain;
