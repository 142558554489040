import { useState, useCallback, useEffect } from 'react';
import { BACKEND_URL } from '../../assets/constants.js';
import { apiRequest } from '../../util/apiRequest.ts';


interface CreateTransactionDtoReturn {
  typeResp: string;
  textResp: string;

  nicknameBot?: string;
  dateRegBot?: string;
  SteamIDBot?: string;
  tradeId?: string;
  transactionId?: number;
  status?: string;
  createdAt?: number;
};


const useFetchTransaction = (id: true) => {
  const [data, setData] = useState<CreateTransactionDtoReturn | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      if (!id) setData(null);
      else {
        const responseData = await apiRequest<CreateTransactionDtoReturn>(
          `/api/transaction/${id}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        setData(responseData);
      }
    } catch (err) {
      setError(err as Error);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchData();
  }, []);

  return { data, loading, error, refetch: fetchData };
};

export default useFetchTransaction;