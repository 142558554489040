import "./FAQ.css";

const FAQ = () => {
  return (
    <div className="faq_container">
      <h2>FAQ</h2>
      <div className="faq_container-blocks">
        <div>
          <h1>01</h1>
          <div>
            <h4>Вы получите много преимуществ от наших функций ?</h4>
            <p>
              Вы получите много преимуществ от наших функций Вы получите много
              преимуществ от наших функций{" "}
            </p>
          </div>
        </div>

        <div>
          <h1>02</h1>
          <div>
            <h4>Вы получите много преимуществ от наших функций ?</h4>
            <p>
              Вы получите много преимуществ от наших функций Вы получите много
              преимуществ от наших функций{" "}
            </p>
          </div>
        </div>


        <div>
          <h1>03</h1>
          <div>
            <h4>Вы получите много преимуществ от наших функций ?</h4>
            <p>
              Вы получите много преимуществ от наших функций Вы получите много
              преимуществ от наших функций{" "}
            </p>
          </div>
        </div>


        <div>
          <h1>04</h1>
          <div>
            <h4>Вы получите много преимуществ от наших функций ?</h4>
            <p>
              Вы получите много преимуществ от наших функций Вы получите много
              преимуществ от наших функций{" "}
            </p>
          </div>
        </div>


        <div>
          <h1>05</h1>
          <div>
            <h4>Вы получите много преимуществ от наших функций ?</h4>
            <p>
              Вы получите много преимуществ от наших функций Вы получите много
              преимуществ от наших функций{" "}
            </p>
          </div>
        </div>

        <div>
          <h1>06</h1>
          <div>
            <h4>Вы получите много преимуществ от наших функций ?</h4>
            <p>
              Вы получите много преимуществ от наших функций Вы получите много
              преимуществ от наших функций{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
