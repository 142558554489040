export const getNameWeapon = (name) => {
    // звездочку вырезать, износ оружия вырезать отдельно и обрезать по первым буквам
//stattrak - ST
//souvenir - SV
//в стикерах убирать слово стикер
//asset - idInInventory
    const floats = {
        "(Factory New)": "FN",
        "(Field-Tested)": "FT",
        "(Well-Worn)": "WW",
        "(Battle-Scarred)": "BS",
        "(Minimal Wear)": "MW"
    }
    const subStringRemove = ["StatTrak™", "|", "★", "Souvenir", "(Factory New)", "(Field-Tested)", "(Well-Worn)", "(Battle-Scarred)", "(Minimal Wear)"];
    const result = subStringRemove.reduce((line, substring) => line.replace(substring, ""), name);
    let float = "";
    const wordsCheck = name.split(" ")
    const wordCount = wordsCheck.length
    // console.log(star)
    const space = result.trim().indexOf(" ")
    const space2 = result.trim().indexOf(" ", space + 1)
    const part1 =  result.trim().slice(0, wordCount === 2 ? space : space2 + 1);
    const startTrack = name.includes("StatTrak™")
    const souvenirCheck = name.includes("Souvenir")
    const part2 = result.trim().slice(wordCount === 2 ? space : space2 + 1)
    for (const substring in floats) {
        if (name.includes(substring)) {
            float = floats[substring];
            break;
        }
    }
    return {part1, part2, startTrack, souvenirCheck, float}
}