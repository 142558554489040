import "./MainWindowNotLogin.css";
import Coin from "../../../assets/img/coin.svg";
import IconMain from "../../../assets/img/iconMain.svg";
import Information from "../../../assets/img/information.svg";
import Steam from "../../../assets/img/steam.svg";
import Flash from "../../../assets/img/flash.svg";
import MoneyBlue from "../../../assets/img/money-recive-blue.svg";
import Timer from "../../../assets/img/timer-pause.svg";
import { BACKEND_URL } from "../../../assets/constants";

const MainWindowNotLogin = () => {
  return (
    <div className="main-window main-window-bg">
      <div className="left-column-item games">
        <div>CS 2</div>
        <div>DOTA 2</div>
        <div>TF2</div>
        <div>RUST</div>
      </div>
      <div className="left-column-item title">
        Продай скины в пару кликов и{" "}
        <spna className="title blue">зарабатывай</spna>
      </div>
      <div className="left-column-item desc">
        Продавай скину по щелку пальца и получай бесплатные деньги и не нуждайся
        в чужой помощи бла бла бла Продавай скину по щелку пальца
      </div>
      <div className="left-column-item">
        <a href={`${BACKEND_URL}/api/auth/steam`} className="sell-but">
          <img src={Coin} />
          <span>Продать скины</span>
        </a>
      </div>
      <div className="icon-main">
        <img src={IconMain} />
        <div className="icon-main_block-right">
          <img src={Information} />

          <div className="icon-main_block-right-block">
            <span className="icon-main_block-right-title">
              Login with steam
            </span>
            <span className="icon-main_block-right-desc">
              Please login for steam
            </span>
          </div>

          <div className="login">
            <img onClick={() =>  window.open(`${BACKEND_URL}/api/auth/steam`)} src={Steam} />
            <a href={`${BACKEND_URL}/api/auth/steam`}>Login with Steam</a>
          </div>
        </div>
      </div>

      <div className="centr-column-item">
        <div>
          <div>
            <img src={Flash} />
            <span>100% безопасность</span>
          </div>
          <span className="centr-column-item-desc">
            Все транзакции защищены{" "}
            <span className="centr-column-item-desc_gray">
              256 битным SSL
              <br /> сертификатом.
            </span>
          </span>
        </div>
        <div>
          <div>
            <img src={MoneyBlue} />
            <span>Без скрытых комиссий</span>
          </div>
          <span className="centr-column-item-desc">
            Вся информация по сделке, включая комиссию, <br />
            <span className="centr-column-item-desc_gray">
              будет указана при оформлении заказа.
            </span>
          </span>
        </div>
        <div>
          <div>
            <img src={Timer} />
            <span>Быстрые выплаты</span>
          </div>
          <span className="centr-column-item-desc">
            Обычно принято ввести все продажи в эксель файлике, но это
            <br /> забирает много {' '}
            <span className="centr-column-item-desc_gray">
              времени, мы же сохраняем и выводим
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default MainWindowNotLogin;
