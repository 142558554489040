import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';

// Определяем тип для декодированного токена
interface DecodedToken {
  "username": string,
  "id": number,
  "SteamID": string,
  "photo": string,
  "role": string,
  "iat": number,
  "exp": number
}

const useUser = (): DecodedToken | null => {
  const [decodedToken, setDecodedToken] = useState<DecodedToken | null>(null);
  useEffect(() => {
    const checkToken = () => {
      const token = Cookies.get('access_token');
     
      if (token) {
        try {
          const decoded: DecodedToken = jwtDecode(token);
          // console.log('Decoded JWT:', decoded);
          setDecodedToken(decoded);
        } catch (error) {
          console.error('Invalid token:', error);
          setDecodedToken(null);
        }
      } else {
        setDecodedToken(null);
      }
    };

    checkToken();

    const intervalId = setInterval(checkToken, 3000);

    return () => clearInterval(intervalId);
  }, []);

  return decodedToken;
};

export default useUser;
