import { useState, useCallback, useEffect } from 'react';
import { BACKEND_URL } from '../../assets/constants.js';
import { apiRequest } from '../../util/apiRequest.ts';


interface PaymentType {
  id: number;
  title: string;
  desc?: string;
  disabeled: boolean;
  metaData?: any; // Или, если у вас есть конкретная структура JSON, замените на соответствующий тип
  img?: string;
}


const useFetchPayment = (params: {}) => {
  const [data, setData] = useState<PaymentType[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const responseData = await apiRequest<PaymentType[]>(
        `/api/payment/types`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      setData(responseData);
    } catch (err) {
      setError(err as Error);
    } finally {
      setLoading(false);
    }
  }, [params]);

  useEffect(() => {
    fetchData();
  }, []);

  return { data, loading, error, refetch: fetchData };
};

export default useFetchPayment;