import { useState, useCallback, useEffect } from "react";
import { BACKEND_URL } from "../../assets/constants.js";
import { apiRequest } from "../../util/apiRequest.ts";

interface TransactionDto {
  data: {
    type: string;
    metaData: {
      email: string;
      items: any;
      fullName: string;
      paymentId: number;
      walletNumber: string;
      telegramContact: string;
    };
    id: number;
    status: string;
    cost: number;
    createdAt: Date;
    paymentType: { title: string };
  }[],
  count: number;
}

const useFetchTransaction = (initialQuery: {page: number}) => {
  const [query, setQuery] = useState(initialQuery);
  const [data, setData] = useState<TransactionDto | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = useCallback(async (queryParams = query) => {
    setLoading(true);
    setError(null);
    try {
      const responseData = await apiRequest<TransactionDto>(
        `/api/user/transaction`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          queryParams, // Use the provided queryParams
        }
      );
      setData(responseData);
    } catch (err) {
      setError(err as Error);
    } finally {
      setLoading(false);
    }
  }, [query]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const refetch = useCallback((newQuery: {page: number}) => {
    setQuery(newQuery);
    fetchData(newQuery);
  }, [fetchData]);

  return { data, loading, error, refetch };
};

export default useFetchTransaction;
