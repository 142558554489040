import useUser from "../hooks/user/useUser.ts";
import DefaultImageUser from "../assets/img/defaultImageUser.png";

import "./Header.css"; // Подключение стилей
import { Link } from "react-router-dom";
import DropdownMenu from "./component/DropdownMenu/DropdownMenu.js";
import { BACKEND_URL } from "../assets/constants.js";

export const LoginBlock = () => {
  const user = useUser();

  const dropdownItems = [
    // <Link to="/profile">Профиль</Link>,
    <a className="link danger" href={`${BACKEND_URL}/api/auth/logout`}>Выйти</a>
  ];

  return (
    <div className={`login__block`}>
      <img src={user?.photo || DefaultImageUser} />
      <div>
        {/* <div>
          <span className={`login__block-balance`}>{user.balance.toLocaleString()} $</span>
          <img src={ArrowDown} />
        </div>
        <span>Пополнить баланс</span> */}
        <div>
          <Link className="link" to={'/profile'}>Профиль</Link>
          <DropdownMenu items={dropdownItems} />
        </div>
      </div>
    </div>
  );
};
