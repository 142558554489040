import React, { useState } from "react";

const SvgIcon5 = ({ isHovered }) => {
  const strokeColor = isHovered ? "#6EAEFA" : "#797979";
  const transitionStyle = {
    transition: "stroke 0.2s",
  };

  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3335 4.99935V7.01601C18.3335 8.33268 17.5002 9.16602 16.1835 9.16602H13.3335V3.34101C13.3335 2.41601 14.0918 1.66602 15.0168 1.66602C15.9252 1.67435 16.7585 2.04101 17.3585 2.64101C17.9585 3.24935 18.3335 4.08268 18.3335 4.99935Z"
        stroke={strokeColor}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        style={transitionStyle}
      />
      <path
        d="M1.6665 5.83268V17.4993C1.6665 18.191 2.44984 18.5827 2.99984 18.166L4.42484 17.0993C4.75817 16.8493 5.22484 16.8827 5.52484 17.1827L6.90817 18.5744C7.23317 18.8994 7.7665 18.8994 8.0915 18.5744L9.4915 17.1744C9.78317 16.8827 10.2498 16.8493 10.5748 17.0993L11.9998 18.166C12.5498 18.5744 13.3332 18.1827 13.3332 17.4993V3.33268C13.3332 2.41602 14.0832 1.66602 14.9998 1.66602H5.83317H4.99984C2.49984 1.66602 1.6665 3.15768 1.6665 4.99935V5.83268Z"
        stroke={strokeColor}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        style={transitionStyle}
      />
      <path
        d="M5.2251 11.4426L9.7751 6.89258"
        stroke={strokeColor}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={transitionStyle}
      />
      <path
        d="M9.93748 11.2507H9.94497"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={transitionStyle}
      />
      <path
        d="M5.16209 7.08268H5.16957"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={transitionStyle}
      />
    </svg>
  );
};

export default SvgIcon5;
