import "./BlockInfoUser.css";
import useFetchUser from "../../../hooks/user/useFetchUser.ts";
import { useDebounce } from "use-debounce";
import useWithMethodFetch from "../../../hooks/useWithMethodFetch.ts";
import { useEffect, useState } from "react";
import useFetchTransaction from "../../../hooks/user/useFetchTransaction.ts";
import classNames from "classnames";
import { formatDate } from "../../../util/formatDate.ts";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";

const buttons = ["$ Transaction"];

const BlockInfoUser = ({ user }) => {
  const [page, setPage] = useState(0); // react-paginate is zero-indexed

  const userData = useFetchUser();
  const dataTransaction = useFetchTransaction({ page: page + 1 }); // Adjust for one-indexed API

  const updateUser = useWithMethodFetch(`/api/user/update`);

  const [selectedButton, setSelectedButton] = useState(buttons[0]);

  const [tradeUrl, setTradeUrl] = useState("");
  const [email, setEmail] = useState("");

  const [debouncedTradeUrl] = useDebounce(tradeUrl, 800);
  const [debouncedEmail] = useDebounce(email, 800);

  useEffect(() => {
    if (debouncedTradeUrl && debouncedTradeUrl?.trim()) {
      (async () => {
        await updateUser.request({
          method: "PATCH",
          body: { tradeurl: debouncedTradeUrl.trim() },
        });
        userData.refetch();
      })();
    }
  }, [debouncedTradeUrl]);

  useEffect(() => {
    if (debouncedEmail?.trim()) {
      (async () => {
        await updateUser.request({
          method: "PATCH",
          body: { email: debouncedEmail.trim() },
        });
        userData.refetch();
      })();
    }
  }, [debouncedEmail]);

  const handlePageClick = (event) => {
    setPage(event.selected);
    dataTransaction.refetch({ page: event.selected+1 })
  };

  const pageCount = Math.ceil((dataTransaction.data?.count || 0) / 30);

  return (
    <>
      <div className="block-info-user">
        <img src={user.photo} className="block-info-user_avatar" />
        <span className="block-info-user_nick">{user.username}</span>

        <span className="block-info-user_balance">0.00 $</span>
        <div className="block-info-user_tradeurl">
          <span>Trade URL</span>
          <input
            onChange={(e) => setTradeUrl(e.target.value)}
            defaultValue={userData.data?.tradeurl}
          />
        </div>
        <div className="block-info-user_email">
          <span>Email</span>
          <input
            onChange={(e) => setEmail(e.target.value)}
            defaultValue={userData.data?.email}
          />
        </div>

        <div className="block-info-user_countTransaction">
          <h2>{userData.data?.transactionCount}</h2>
          <span>Сделок совершенно</span>
        </div>
        <div className="block-info-user_summaTransaction">
          <h2>
            {userData.data?.totalTransactionSum}
            <span> $</span>
          </h2>
          <span>Сумму сделок</span>
        </div>
      </div>
      <div className="block-info-user_buttons-select">
        {buttons.map((v) => (
          <button
            className={classNames({
              "block-info-user_buttons-select-active": selectedButton == v,
            })}
            onClick={() => setSelectedButton(v)}
          >
            $ Transaction
          </button>
        ))}
      </div>
      {selectedButton == "$ Transaction" && (
        <div className="block-info-user_transaction">
          <div className="block-info-user_transaction-header">
            <h2>Transaction</h2>
            <div></div>
          </div>

          <table>
            <thead>
              <tr>
                <th>TYPE / DATE</th>
                <th>ID</th>
                <th>STATUS</th>
                <th>SUM</th>
                <th>PAYMENT</th>
                <th>TO</th>
              </tr>
            </thead>
            <tbody>
              {dataTransaction.data &&
                dataTransaction.data.data.map((v) => (
                  <tr key={v.id}>
                    <td>
                      {v.type}
                      <br />
                      <span>{formatDate(new Date(v.createdAt))}</span>
                    </td>
                    <td><Link to={`/sell/transaction/${v.id}`}>{v.id.toString().padStart(6, '0')}</Link></td>
                    <td>
                      <span className={`status ${v.status}`}>{v.status}</span>
                    </td>
                    <td>{v.cost} $</td>
                    <td>{v.paymentType.title}</td>
                    <td>{v.metaData.walletNumber}</td>
                  </tr>
                ))}
            </tbody>
          </table>

          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            activeClassName={"active"}
          />
        </div>
      )}
    </>
  );
};

export default BlockInfoUser;
