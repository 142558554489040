import React from "react";
import { getNameWeapon } from "./getNameWeapon";
import "./ItemBlock.css";
import Clock from "../../../assets/img/clock.svg";
import { timeAgo } from "./timeAgo";
import { Tooltip } from "react-tooltip";
import classNames from "classnames";

export const ItemBlock = ({ item, howOld = false, selectItem = () => {} }) => {
  const title = getNameWeapon(item.hash_name);
  const img =
    item.icon_url && item.icon_url.includes("http")
      ? item.icon_url
      : `https://community.akamai.steamstatic.com/economy/image/${item.icon_url}`;

  const name = `${title.startTrack ? `(ST) ` : ""}${
    title.souvenirCheck ? `(SV) ` : ""
  }${title.part1} | ${title.part2}`;

  const price = (howOld || item.allowed) && (item?.priceBuy || item?.priceOnTransaction);

  return (
    <div
      className={classNames({
        item_container: true,
        item_select: item?.isSelect,
      })}
      onClick={() => price && selectItem(item)}
    >
      <div
        className="item_container-unvaileble"
        style={{ display: price ? "none" : "block" }}
      >
        &#32;
      </div>
      {howOld ? (
        <div className="item_container-howold_block">
          <img src={Clock} />
          <span className="item_container-howold_block-span">
            {timeAgo(item.createdAt)}
          </span>
        </div>
      ) : (
        <div className="item_container-sticker-float-bd">
          <div className="item_container-sticker-float-bd-stickers">
            {item.stickers.map((sticker, index) => (
              <React.Fragment key={index}>
                <img
                  src={sticker.src}
                  data-tooltip-content={sticker.name} // Tooltip content
                  data-tooltip-id={`tooltip-${index}`} // Unique ID for each tooltip
                />
                <Tooltip id={`tooltip-${index}`} />
              </React.Fragment>
            ))}
          </div>
          <span>{title.float}</span>
        </div>
      )}
      <img className="item_container-img" src={img} />
      <p className="item_container-title" title={name}>
        {name}
      </p>
      <div className="item_container-price">
        <p>{price ? `${price} $` : "Недоступно"}</p>
      </div>
      <div
        style={{
          backgroundColor: item.color,
          boxShadow: `0px 0px 11px ${item.color}`,
        }}
        className="item_container-color"
      ></div>
    </div>
  );
};
