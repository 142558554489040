export function formatDate(date) {
    // Преобразуем переданную дату в объект Date
    const d = new Date(date);
  
    // Получаем часы и минуты с добавлением ведущих нулей, если необходимо
    const hours = d.getHours().toString().padStart(2, '0');
    const minutes = d.getMinutes().toString().padStart(2, '0');
  
    // Получаем день, месяц и год с добавлением ведущих нулей
    const day = d.getDate().toString().padStart(2, '0');
    const month = (d.getMonth() + 1).toString().padStart(2, '0'); // Месяцы идут с 0, поэтому добавляем 1
    const year = d.getFullYear();
  
    // Формируем строку в формате "HH:mm, dd.MM.yyyy"
    return `${hours}:${minutes}, ${day}.${month}.${year}`;
  }