import React, { useState } from "react";

const SvgIcon = ({ isHovered }) => {
  const strokeColor = isHovered ? "#6EAEFA" : "#797979";
  const transitionStyle = {
    transition: "0.18s",
  };

  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.3125 12.0322C8.3125 12.8809 8.96876 13.5634 9.77376 13.5634H11.4187C12.1187 13.5634 12.6875 12.9684 12.6875 12.2247C12.6875 11.4284 12.3375 11.1397 11.8213 10.9559L9.1875 10.0372C8.67125 9.85343 8.32126 9.57344 8.32126 8.76844C8.32126 8.03344 8.88999 7.42969 9.58999 7.42969H11.235C12.04 7.42969 12.6963 8.11219 12.6963 8.96094"
        stroke={strokeColor}
        strokeWidth="1.3125"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={transitionStyle}
      />
      <path
        d="M10.5 6.5625V14.4375"
        stroke={strokeColor}
        strokeWidth="1.3125"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={transitionStyle}
      />
      <path
        d="M19.25 10.5C19.25 15.33 15.33 19.25 10.5 19.25C5.67 19.25 1.75 15.33 1.75 10.5C1.75 5.67 5.67 1.75 10.5 1.75"
        stroke={strokeColor}
        strokeWidth="1.3125"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={transitionStyle}
      />
      <path
        d="M19.25 5.25V1.75H15.75"
        stroke={strokeColor}
        strokeWidth="1.3125"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={transitionStyle}
      />
      <path
        d="M14.875 6.125L19.25 1.75"
        stroke={strokeColor}
        strokeWidth="1.3125"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={transitionStyle}
      />
    </svg>
  );
};

export default SvgIcon;
