import "./StatsBlock.css";
import Dollar from "../../../assets/img/dollar-circle.svg";
import Profile from "../../../assets/img/profile-2user.svg";
import Cube from "../../../assets/img/3d-cube-scan.svg";
import Burger from "../../../assets/img/burger.svg";
import useFetchInfo from "../../../hooks/feedback/useFetchInfo.ts";

const StatsBlock = () => {
  const info = useFetchInfo();

  return (
    <div className="stats_block_container">
      <div>
        <img src={Cube} />
        <div>
          <h3>{info.data?.skin_transaction_count}</h3>
          <p>Проданно предмето на маркете</p>
        </div>
      </div>

      <div>
        <img src={Profile} />
        <div>
        <h3>{info.data?.user_count}</h3>
          <p>Счастливых клиентов</p>
        </div>
      </div>

      <div>
        <img src={Burger} />
        <div>
        <h3>{info.data?.feedback_count}</h3>
          <p>Отзывы о нашем проекте</p>
        </div>
      </div>


      {/* <div>
        <img src={Dollar} />
        <div>
          <h3>-</h3>
          <p>Выплачено партнерам</p>
        </div>
      </div> */}
    </div>
  );
};

export default StatsBlock;
