import React, { useState } from "react";

const SvgIcon3 = ({ isHovered }) => {
  const strokeColor = isHovered ? "#6EAEFA" : "#797979";
  const transitionStyle = {
    transition: "stroke 0.2s",
  };

  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0335 11.2904C14.6835 11.632 14.4835 12.1237 14.5335 12.6487C14.6085 13.5487 15.4335 14.207 16.3335 14.207H17.9168V15.1987C17.9168 16.9237 16.5085 18.332 14.7835 18.332H6.36683C7.0585 17.7237 7.50016 16.832 7.50016 15.832C7.50016 13.9904 6.0085 12.4987 4.16683 12.4987C3.3835 12.4987 2.6585 12.7737 2.0835 13.232V9.59037C2.0835 7.86537 3.49182 6.45703 5.21682 6.45703H14.7835C16.5085 6.45703 17.9168 7.86537 17.9168 9.59037V10.7904H16.2335C15.7668 10.7904 15.3418 10.9737 15.0335 11.2904Z"
        stroke={strokeColor}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={transitionStyle}
      />
      <path
        d="M2.0835 9.59103V6.53272C2.0835 5.54105 2.69183 4.65768 3.61683 4.30768L10.2335 1.80768C11.2668 1.42435 12.3752 2.1827 12.3752 3.29104V6.45769"
        stroke={strokeColor}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={transitionStyle}
      />
      <path
        d="M18.7992 11.641V13.3577C18.7992 13.816 18.4325 14.191 17.9658 14.2077H16.3325C15.4325 14.2077 14.6075 13.5493 14.5325 12.6493C14.4825 12.1243 14.6825 11.6327 15.0325 11.291C15.3408 10.9743 15.7658 10.791 16.2325 10.791H17.9658C18.4325 10.8077 18.7992 11.1826 18.7992 11.641Z"
        stroke={strokeColor}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={transitionStyle}
      />
      <path
        d="M5.8335 10H11.6668"
        stroke={strokeColor}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={transitionStyle}
      />
      <path
        d="M7.50016 15.8333C7.50016 16.8333 7.0585 17.725 6.36683 18.3333C5.77516 18.85 5.0085 19.1667 4.16683 19.1667C2.32516 19.1667 0.833496 17.675 0.833496 15.8333C0.833496 14.7833 1.31683 13.8417 2.0835 13.2333C2.6585 12.775 3.3835 12.5 4.16683 12.5C6.0085 12.5 7.50016 13.9917 7.50016 15.8333Z"
        stroke={strokeColor}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={transitionStyle}
      />
      <path
        d="M4.37516 14.791V16.041L3.3335 16.666"
        stroke={strokeColor}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={transitionStyle}
      />
    </svg>
  );
};

export default SvgIcon3;
