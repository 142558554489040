import { useState, useCallback, useEffect } from 'react';
import { BACKEND_URL } from '../../assets/constants.js';
import { apiRequest } from '../../util/apiRequest.ts';

interface UserInventory {
  assetId: string;
  classid?: string;
  instanceid?: string;
  hash_name?: string;
  float?: number;
  paint_index?: number;
  paint_seed?: number;
  icon_url?: string;
  rungame?: string;
  color?: string;
  stickers?: any; // Adjust this type if you know the structure of the JSON
  createdAt: string; // Assuming DateTime is represented as a string
  SteamID?: string;
  appid?: string;
  contextid?: string;
}

interface InventoryParams {
  SteamID: string;
  appid: string;
  contextid: string;
}

const useFetchInventory = (params: InventoryParams) => {
  const [data, setData] = useState<UserInventory[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const responseData = await apiRequest<UserInventory[]>(
        `/api/steam/inventory?SteamID=${params.SteamID}&appid=${params.appid}&contextid=${params.contextid}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      setData(responseData);
    } catch (err) {
      setError(err as Error);
    } finally {
      setLoading(false);
    }
  }, [params]);

  useEffect(() => {
    fetchData();
  }, []);

  return { data, loading, error, refetch: fetchData };
};

export default useFetchInventory;