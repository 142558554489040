import { useEffect, useState } from "react";
import useFetchInventory from "../../../hooks/steam/useFetchInventory.ts";
import useFetchUser from "../../../hooks/user/useFetchUser.ts";
import Dota2 from "../../../assets/img/dota2.svg";
import Cs from "../../../assets/img/cs.svg";
import Rust from "../../../assets/img/rust.svg";
import Tf2 from "../../../assets/img/tf2.svg";
import Refresh from "../../../assets/img/refresh.svg";
import "./MainWindow.css";
import { useDebounce } from "use-debounce";
import useWithMethodFetch from "../../../hooks/useWithMethodFetch.ts";
import { ItemBlock } from "../ItemBlock/ItemBlock.js";
import classNames from "classnames";
import Payment from "./Payment.js";
import { toast } from "react-toastify";
import Transaction from "./Transaction.js";
import { useParams } from "react-router-dom";
import useFetchTransaction from "../../../hooks/payment_transaction/useFetchTransaction.ts";

const MainWindow = ({ user }) => {
  const [appid, setAppid] = useState(730); // Default is CS:GO
  const [contextid, setContextid] = useState(2); // Default contextid
  const { transactionId } = useParams();

  // {
  //   typeResp: "success",
  //   textResp: "Транзакция создана",
  //   tradeId: "7361608943",
  //   SteamIDBot: "737373737737373",
  //   transactionId: 2,
  //   status: "create",
  //   createdAt: new Date().getTime(),
  // }

  const [selectedItems, setSelectedItems] = useState({
    count: 0,
    summa: 0,
    items: [],
  });

  const [searchName, setSearchName] = useState("");

  const [inventory, setInventory] = useState([]);

  const updateUser = useWithMethodFetch(`/api/user/update`);
  const updateSteamInventory = useWithMethodFetch(
    `/api/steam/inventory/refresh`
  );
  const userData = useFetchUser();
  const transactionData = useFetchTransaction(transactionId);
  const userDataInventory = useFetchInventory({
    SteamID: user.SteamID,
    appid,
    contextid,
  });

  const [tradeUrl, setTradeUrl] = useState("");
  const [transaction, setTransaction] = useState();
  const [debouncedTradeUrl] = useDebounce(tradeUrl, 800);

  useEffect(() => {
    if (transactionData?.data) setTransaction(transactionData.data);
  }, [transactionData.data]);

  useEffect(() => {
    if (transactionId) transactionData.refetch();
    else setTransaction();
  }, [transactionId]);

  useEffect(() => {
    const newSelectedItems = inventory.reduce(
      (all, cur) => {
        all.count += +!!cur?.isSelect;
        if (cur?.isSelect) {
          all.items.push({
            assetid: cur.assetId,
            appid: cur.appid,
            contextid: cur.contextid,
            priceBuy: cur.priceBuy
          });
          all.summa += cur?.priceBuy || 0;
        }

        return all;
      },
      { count: 0, summa: 0, items: [] }
    );

    newSelectedItems.summa = +newSelectedItems.summa.toFixed(2);

    setSelectedItems(newSelectedItems);
  }, [inventory]);

  useEffect(() => {
    if (debouncedTradeUrl?.trim()) {
      (async () => {
        await updateUser.request({
          method: "PATCH",
          body: { tradeurl: debouncedTradeUrl.trim() },
        });
        userData.refetch();
      })();
    }
  }, [debouncedTradeUrl]);

  useEffect(() => {
    setInventory([]);
    userDataInventory.refetch();
  }, [appid, contextid]);

  useEffect(() => {
    const filteredItems = (userDataInventory.data || []).filter((item) => {
      let filtered = true;
      if (searchName && filtered) {
        filtered = item.hash_name
          .toLowerCase()
          .includes(searchName.toLowerCase());
      }

      return filtered;
    });

    setInventory(filteredItems);
  }, [userDataInventory.data, searchName]);

  const handleGameClick = (appid, contextid) => {
    setAppid(appid);
    setContextid(contextid);
  };

  const handlerefreshInventory = async () => {
    toast.info("Обновление инвентаря...");

    await updateSteamInventory.request({
      method: "GET",
      queryParams: { SteamID: user.SteamID, appid, contextid },
    });

    userDataInventory.refetch();

    toast.success("Инвентарь обновлен");
  };

  useEffect(() => {
    if (userDataInventory?.data && !userDataInventory?.data?.length)
      handlerefreshInventory();
  }, []);

  const selectItem = (item) => {
    setInventory(
      inventory.map((v) => ({
        ...v,
        isSelect: item.allowed ?  item.assetId == v.assetId ? !v?.isSelect : v?.isSelect : false,
      }))
    );
  };

  const selectItemAll = () => {
    setInventory(
      inventory.map((item) => ({
        ...item,
        isSelect: item.allowed ?  !selectedItems.count: false,
      }))
    );
  };

  return (
    <div className="main-window-auth main-window-bg">
      {transaction ? (
        <Transaction transactionData={transactionData} transaction={transaction} />
      ) : (
        <>
          <div className="main-window-auth__left_block">
            {!userData.data?.tradeurl && (
              <div className="main-window-auth__left_block__tradeurl">
                <input
                  placeholder="Trade link"
                  onChange={(e) => {
                    setTradeUrl(e.target.value);
                  }}
                />
                <a
                  target="_blank"
                  href={`https://steamcommunity.com/profiles/${user.SteamID}/tradeoffers/privacy#trade_offer_access_url`}
                >
                  Где найти?
                </a>
              </div>
            )}
            <div className="main-window-auth__left_block__filter">
              <div className="main-window-auth__left_block__filter-blocks-game">
                <div
                  onClick={() => handleGameClick(730, 2)}
                  style={{
                    backgroundColor: appid === 730 ? "#6EAEFA" : "#2f3441",
                  }}
                >
                  <img src={Cs} alt="CS:GO" />
                </div>
                <div
                  onClick={() => handleGameClick(570, 2)}
                  style={{
                    backgroundColor: appid === 570 ? "#6EAEFA" : "#2f3441",
                  }}
                >
                  <img src={Dota2} alt="Dota 2" />
                </div>
                <div
                  onClick={() => handleGameClick(252490, 2)}
                  style={{
                    backgroundColor: appid === 252490 ? "#6EAEFA" : "#2f3441",
                  }}
                >
                  <img src={Rust} alt="Rust" />
                </div>
                <div
                  onClick={() => handleGameClick(440, 2)}
                  style={{
                    backgroundColor: appid === 440 ? "#6EAEFA" : "#2f3441",
                  }}
                >
                  <img src={Tf2} alt="Team Fortress 2" />
                </div>
              </div>

              <div className="main-window-auth__left_block__filter-blocks-filter">
                <div className="main-window-auth__left_block__filter-blocks-filter_serach">
                  <input
                    placeholder="Name skin"
                    onChange={(e) => {
                      setSearchName(e.target.value);
                    }}
                  />
                </div>
                <button
                  onClick={selectItemAll}
                  className="main-window-auth__left_block__filter-blocks-filter_select "
                >
                  {selectedItems.count ? "Unselect All" : "Select All"}
                </button>
                <button
                  onClick={handlerefreshInventory}
                  className="main-window-auth__left_block__filter-blocks-filter_refresh "
                >
                  <img
                    className={classNames({
                      "main-window-auth__left_block__filter-blocks-filter_refresh_spin":
                        updateSteamInventory.loading ||
                        userDataInventory.loading,
                    })}
                    src={Refresh}
                  />
                </button>
              </div>
            </div>
            <div className="main-window-auth__left_block__items">
              {inventory && inventory.length ? (
                inventory.map((item) => (
                  <ItemBlock selectItem={selectItem} item={item} />
                ))
              ) : (
                <span className="main-window-auth__left_block__items_no-items">
                  Мы не нашли предметы, если считаете это неправдой, то
                  пожалуйста нажмите "обновить инвентарь"
                </span>
              )}
            </div>
          </div>
          <div className="main-window-auth__right_block">
            {!selectedItems.count ? (
              <span className="main-window-auth__right_block__selected">
                Not selected items
              </span>
            ) : (
              <Payment selectedItems={selectedItems} userData={userData} />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default MainWindow;
