export function timeAgo(createdAt) {
    const now = new Date();
    const createdTime = new Date(createdAt);
    const diffInMilliseconds = now - createdTime;

    const minutes = Math.floor(diffInMilliseconds / (1000 * 60));
    const hours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
    const days = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));

    if (minutes < 60 && hours === 0) {
        return `${minutes} ${declineMinutes(minutes)} назад`;
    } else if (hours < 24 && days === 0) {
        return `${hours} ${declineHours(hours)} назад`;
    } else {
        return `${days} ${declineDays(days)} назад`;
    }
}

function declineMinutes(minutes) {
    if (minutes % 10 === 1 && minutes % 100 !== 11) {
        return 'минута';
    } else if ([2, 3, 4].includes(minutes % 10) && ![12, 13, 14].includes(minutes % 100)) {
        return 'минуты';
    } else {
        return 'минут';
    }
}

function declineHours(hours) {
    if (hours % 10 === 1 && hours % 100 !== 11) {
        return 'час';
    } else if ([2, 3, 4].includes(hours % 10) && ![12, 13, 14].includes(hours % 100)) {
        return 'часа';
    } else {
        return 'часов';
    }
}

function declineDays(days) {
    if (days % 10 === 1 && days % 100 !== 11) {
        return 'день';
    } else if ([2, 3, 4].includes(days % 10) && ![12, 13, 14].includes(days % 100)) {
        return 'дня';
    } else {
        return 'дней';
    }
}
