import axios from "axios";
import { BACKEND_URL } from "../assets/constants";

interface FetchOptions {
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
    body?: Record<string, any>;
    headers?: Record<string, string>;
    queryParams?: Record<string, any>;  // Added queryParams here
}

const getAccessTokenFromCookies = (): string | null => {
    const match = document.cookie.match(new RegExp('(^| )access_token=([^;]+)'));
    return match ? decodeURIComponent(match[2]) : null;
};

const buildQueryParams = (queryParams: Record<string, any> | undefined): string => {
    if (!queryParams) return '';
    const query = new URLSearchParams(queryParams).toString();
    return query ? `?${query}` : '';
};

export const apiRequest = async <T>(url: string, options: FetchOptions): Promise<T> => {
    const accessToken = getAccessTokenFromCookies();
    const queryString = buildQueryParams(options.queryParams);
    const fullUrl = `${BACKEND_URL}${url}${queryString}`;

    const response = await axios(fullUrl, {
        method: options.method,
        headers: {
            "Content-Type": "application/json",
            ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
            ...options.headers,
        },
        data: options.body,
    });

    return response.data;
};
