import { BACKEND_URL } from "../../assets/constants.js";
import useFetch from "../useFetch.ts";

interface Feedback {
  hash_name: string;
  icon_url: string;
  color: string;
  createdAt: Date;
}

const useFetchLiveChart = (params: Record<string, string | number> = {}) => {
  const url = `${BACKEND_URL}/api/transaction/last-success`;
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    params,
  };

  return useFetch<Feedback[]>(url, options);
};

export default useFetchLiveChart;
